import '@angular/compiler';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { ComponentNavAsideComponent } from './component.nav.aside/component.nav.aside.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { ComponentListResourceComponent } from './component.list.resource/component.list.resource.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { ComponentListMetaComponent } from './component.list.meta/component.list.meta.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentListTemplateComponent } from './component.list.template/component.list.template.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PageStrorageComponent } from './page.strorage/page.strorage.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentListUserComponent } from './component.list.user/component.list.user.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PageStoreComponent } from './page.store/page.store.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { ComponentEditUserComponent } from './component.edit.user/component.edit.user.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { MatTreeModule } from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalSeasonViewerTreeComponent,
        PortalSeasonLoadingHexaComponent,
        LayoutAdminComponent,
        PortalDizestCodeflowNoteComponent,
        LayoutAsideComponent,
        ComponentNavAsideComponent,
        PortalDizestEditorUimodeComponent,
        PortalSeasonPagenationComponent,
        PortalDizestDriveComponent,
        ComponentNavAdminComponent,
        PageMypageComponent,
        PortalDizestCodeflowAppinfoComponent,
        ComponentListResourceComponent,
        PortalDizestEditorTextComponent,
        ComponentListMetaComponent,
        PageWorkflowComponent,
        PortalDizestSidebarPackagesComponent,
        PageAdminComponent,
        PortalSeasonAlertComponent,
        PortalDizestSidebarTimerComponent,
        LayoutEmptyComponent,
        ComponentListTemplateComponent,
        PortalDizestHealthComponent,
        PortalDizestAppsComponent,
        PageAuthenticateComponent,
        PortalDizestEditorVideoComponent,
        PortalDizestEditorNotsupportedComponent,
        PortalDizestStatuscardComponent,
        PageStrorageComponent,
        PortalSeasonUiDropdownComponent,
        PortalDizestEditorSettingComponent,
        ComponentListUserComponent,
        PageDetailComponent,
        PortalSeasonStatusbarComponent,
        PortalDizestEditorWorkflowComponent,
        PortalDizestSidebarCodeflowComponent,
        PortalDizestEditorImageComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonLoadingComponent,
        PageStoreComponent,
        PortalDizestSidebarWorkflowinfoComponent,
        PortalDizestWidgetWorkflowNodeComponent,
        PortalSeasonTabComponent,
        PageDashboardComponent,
        ComponentEditUserComponent,
        PortalDizestEditorTerminalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
                MatTreeModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }