import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.edit.user');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-edit-user',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/component.edit.user/view.scss */
.card {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1019607843);
  border-radius: 12px;
  margin: 15px 10px;
  min-height: 390px;
}

.card {
  border-radius: 4px;
}
.card.card-info {
  background: #F0F1F3;
  border: none;
  box-shadow: none;
}
.card.card-info .mb-2 {
  display: flex;
}
.card.card-info .card-title {
  font-weight: 1000;
}
.card.card-info i {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  flex: none;
}
.card.card-info .info-title {
  font-weight: 600;
  width: 96px;
  flex: none;
}
.card.card-info .info-content {
  flex: auto;
  text-align: right;
}

.info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.info-context .form-control,
.info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.info-context:last-child {
  margin-bottom: 0;
}
.info-context .td {
  flex: 1;
}
.info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}`],
})
export class ComponentEditUserComponent implements OnInit {
    @Input() id: any;

    public data;
    public passwd: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");
        if (this.isnew()) this.data = { name: "", email: "", role: "user" };
        else await this.load();
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code !== 200) return alert("에러 발생");
        this.data = data;
        await this.service.render();
    }

    public isnew() {
        return this.id === "new";
    }

    public async update() {
        await this.service.loading.show();
        let user = JSON.parse(JSON.stringify(this.data));
        if (user.password)
            user.password = this.service.auth.hash(user.password);

        const { code, data } = await wiz.call("update", user);
        if (code != 200) return alert("에러 발생");
        this.id = data;
        // await this.load();
        await this.service.loading.hide();
        await this.service.alert.show({ title: "회원정보", message: "회원 정보가 저장되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
    }
}

export default ComponentEditUserComponent;