import '@angular/compiler';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { ComponentNavAsideComponent } from './component.nav.aside/component.nav.aside.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { ComponentListResourceComponent } from './component.list.resource/component.list.resource.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { ComponentListMetaComponent } from './component.list.meta/component.list.meta.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentListTemplateComponent } from './component.list.template/component.list.template.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PageStrorageComponent } from './page.strorage/page.strorage.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentListUserComponent } from './component.list.user/component.list.user.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PageStoreComponent } from './page.store/page.store.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { ComponentEditUserComponent } from './component.edit.user/component.edit.user.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { MatTreeModule } from '@angular/material/tree';

const INDEX_PAGE = "dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAsideComponent,
        "children": [
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "storage",
                component: PageStrorageComponent,
                "app_id": "page.strorage"
            },
            {
                "path": "detail/:id",
                component: PageDetailComponent,
                "app_id": "page.detail"
            },
            {
                "path": "store",
                component: PageStoreComponent,
                "app_id": "page.store"
            },
            {
                "path": "dashboard",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "workflow",
                component: PageWorkflowComponent,
                "app_id": "page.workflow"
            },
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    },
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/:main?/:sub?/:id?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }