import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.store');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-store',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/page.store/view.scss */
/* src/app/page.store/view.scss: no such file or directory */`],
})
export class PageStoreComponent implements OnInit {
    public workflows: any = [];
    public search: any = {
        text: '',
        page: 1,
        category: 'all',
        dump: 10
    }
    public pagenation: any = {
        end: -1,
        start: -1,
    }

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow(true, "/main");
        await this.load();
    }

    public async load(page = 1) {
        this.search.page = page;
        let body = JSON.parse(JSON.stringify(this.search));

        const { code, data } = await wiz.call("search", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        const { rows, total, lastpage } = data;

        this.workflows = rows;
        this.total = total;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async upload() {
        let files = await this.service.file.select({ accept: '.dwp' });
        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        // fd.append("filepath", JSON.stringify(filepath));

        let url = wiz.url('upload');
        const { code } = await this.service.file.upload(url, fd);
        await this.service.render();
        if (code !== 200) return alert("업로드중 에러발생. 파일을 나눠서 다시 업로드해주세요.");
        alert("업로드를 완료했습니다.");
        await this.load(this.search.page)
    }
}

export default PageStoreComponent;