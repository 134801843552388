import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.strorage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { FlatTreeControl } from '@angular/cdk/tree';
import { FileNode, FileDataSource } from "src/libs/drive/file";



@Component({
    selector: 'wiz-page-strorage',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/page.strorage/view.scss */
.offcanvas {
  width: 100%;
  max-width: 720px;
}
.offcanvas textarea.form-control {
  height: auto;
}
.offcanvas .offcanvas-body .btn {
  padding: 8px 16px;
}

.container-full {
  max-width: 1780px;
  height: 100%;
  margin: auto;
}
.container-full .container-title {
  margin-top: 30px;
}
.container-full .container-title .warn {
  margin: 10px 10px;
  color: red;
}
.container-full .container-title label {
  font-weight: bold;
}
.container-full .container-drive {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.container-full .container-drive .folder-list {
  width: 300px;
  height: 100%;
  padding: 10px 0px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: white;
}
.container-full .container-drive .folder-list .list-header {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.container-full .container-drive .folder-list .list-header h4 {
  margin: 0px;
}
.container-full .container-drive .folder-list .list-header:hover {
  background-color: #E9E9E9;
}
.container-full .container-drive .folder-list .list-body {
  margin: 0px 10px;
  padding: 8px 16px;
}
.container-full .container-drive .folder-list .tree-node {
  width: 100%;
  cursor: pointer;
}
.container-full .container-drive .folder-list .tree-node:hover {
  background-color: #E9E9E9;
}
.container-full .container-drive .navigation {
  padding: 8px 16px;
  background: #B0B0E8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.container-full .container-drive table {
  margin-bottom: 0px;
}
.container-full .container-drive table tr {
  background: white;
  border-style: solid;
}
.container-full .container-drive table tr td {
  padding: 10px 16px;
}
.container-full .container-drive table tr td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
}
.container-full .container-drive table tr td .avatar-container.folder {
  background-color: #B0B0E8;
  color: #EDEEF8;
}
.container-full .container-drive table tr td .avatar-container.file {
  background-color: #EDEEF8;
  color: #B0B0E8;
}
.container-full .container-drive table tr td .avatar-container i {
  width: 32px;
  align-items: center;
}
.container-full .button_control {
  position: relative;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.container-full .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.container-full .button_control:hover .tooltip {
  opacity: 1;
}
.container-full .button_control i {
  display: inline-block;
  vertical-align: top;
}
.container-full .button_control .tooltip {
  position: absolute;
  top: -30px;
  left: 240px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.w-1 {
  width: 8px;
  white-space: nowrap;
  text-align: center;
}

.btn-a {
  background: #EDEEF8;
  border-radius: 999px;
}

.btn-b {
  border: 1px solid #B0B0E8;
  background: white;
  border-radius: 999px;
}

.btn-b:hover {
  background-color: #B0B0E8;
  color: white;
}

.blank {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: #5040F1;
}`],
})
export class PageStrorageComponent implements OnInit {
    private storage = null;
    private root = null;
    private base = null;
    private path = null;

    public rootNode: FileNode;
    public treeControl: FlatTreeControl<FileNode>;
    public dataSource: FileDataSource;

    public mkdir = false;
    public dirName = "";
    public selectAll = false;
    public isRenaming = {};
    public macroTF = false;
    public fileSelect = [];
    public selected = "none";
    public show = false;
    public oneMonthAfter: Date;
    public nowDate: Date;

    constructor(@Inject( Service) public service: Service) {
        // this.rootNode = new FileNode('root', this.root, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);
    }

    async ngOnInit() {
        await this.service.init();
        await this.initRoot();
    }

    async initRoot() {
        let { code, data } = await wiz.call("init");
        if (code !== 200) {
            alert("오류가 발생했습니다. 다시 시도해주세요.");
            location.reload();
        }
        this.root = data;
        this.rootNode = new FileNode('root', this.root, 'folder');
        this.base = data;
        // this.dataSource.data = [];
        this.dataSource.data = await this.list(this.rootNode);
        await this.service.render();
    }

    getLevel = (node: FileNode) => node.level;
    isExpandable = (node: FileNode) => node.extended;

    async list(node: FileNode) {
        let { code, data } = await wiz.call("list", { path: this.base });
        let { res, info } = data;
        res = res.map(item => new FileNode(item.name, item.path, item.type, node, node.level + 1));
        console.log(node.path !== this.root, node, this.root, res)
        // this.dataSource.data.concat(res);
        // this.dataSource.data = [...new Set(this.dataSource.data)]
        this.files = info;
        // console.log("list", res, this.dataSource.data, this.files)
        this.resetState();
        await this.service.render();
        return res;
    }

    resetState() {
        this.mkdir = false;
        this.dirName = "";
        this.path = this.base.replace(this.root, '');
        this.selectAll = false;
    }

    async move(node: FileNode) {
        console.log("move", node)
        if (!node) {
            await this.initRoot();
            return;
        }

        if (node.type === "folder") {
            this.base = node.path;
        }

        this.dataSource.data.forEach(node => node.active = false);

        if (node.extended) {
            node.parent.active = true;
            await this.dataSource.toggle(node, false);
            this.base = this.base.substring(0, this.base.lastIndexOf('/'));
            await this.list(node.parent);
            return;
        }

        node.active = true;
        await this.dataSource.toggle(node, true);

        if (node.type === "folder") {
            await this.list(node);
        }
    }

    private async goBack() {
        this.fileSelect = [];
        this.base = this.base.substring(0, this.base.lastIndexOf('/'));
        let res = this.dataSource.data.find(item => item.path === this.base);

        console.log("oldPath", this.dataSource.data, this.base, res)
        if (res) {
            await this.move(res);
        }
    }

    private async open(file) {
        this.fileSelect = [];
        // let filePath = file.path.split(this.root);
        // file.path = this.root + filePath.slice(1).join(this.root);

        let res = this.dataSource.data.find(item => item.name === file.name && item.path === file.path);
        console.log(this.dataSource.data, res, file.path, file.name)
        if (res) {
            await this.move(res);
        }
    }

    async remove() {
        if (!this.hasCheckedFiles()) {
            await this.showAlert("선택된 파일이 없습니다.", "");
            return;
        }

        let alert = await this.showAlert("정말 이 파일을 삭제하겠습니까?", "No");
        if (!alert) return;

        await this.deleteCheckedFiles();
        await this.refresh();
    }

    hasCheckedFiles() {
        return this.files.some(file => file.checked);
    }

    async showAlert(message: string, cancel: string) {
        return await this.service.alert.show({ title: "", message, cancel, action: "OK" });
    }

    async deleteCheckedFiles() {
        for (let file of this.files) {
            if (file.checked) {
                let { code } = await wiz.call("remove", { base: this.base, file: JSON.stringify(file) });
                if (code !== 200) {
                    alert("remove 과정에서 오류가 발생했습니다.");
                    return;
                }
            }
        }
    }

    async refresh(node: FileNode | null = null) {
        console.log("refresh")
        if (node?.parent) {
            await this.dataSource.toggle(node, false);
            await this.dataSource.toggle(node, true);
        } else {
            await this.list();
        }
    }

    async create() {
        if (!this.mkdir) {
            this.mkdir = true;
            this.focusInput("#mkdir");
        } else if (this.dirName.length > 0) {
            await this.createDirectory();
        }
        await this.service.render();
    }

    focusInput(selector: string) {
        setTimeout(() => document.querySelector(selector).focus(), 300);
    }

    async createDirectory() {
        let { code } = await wiz.call("create", { base: this.base, dirName: this.dirName });
        if (code === 201) {
            this.showError("이미 존재하는 이름입니다.");
            this.focusInput("#mkdir");
        } else if (code === 200) {
            this.mkdir = false;
            await this.refreshCurrentNode();
        }
    }

    async refreshCurrentNode() {
        let res = this.dataSource.data.find(item => item.path === this.base);
        await this.refresh(res);
    }

    async upload(data = null) {
        let files = data || await this.service.file.select({ accept: '.xls,.xlsx,.csv', multiple: true });
        await this.uploadFiles(files);
        await this.refreshCurrentNode();
    }

    async uploadFiles(files) {
        let fd = new FormData();
        files.forEach(file => {
            file.filepath = this.base + (file.filepath ? "/" + file.filepath.split('/').slice(-1)[0] : "");
            fd.append('file[]', file);
        });
        await this.service.file.upload(wiz.url('upload'), fd);
    }

    public async download(file) {

        const fileName = btoa(encodeURIComponent(file.name));
        let download = wiz.url("download?path=" + this.base + "&name=" + fileName)
        window.open(download, '_blank');

    }
    private async rename(file, i) {
        if (!this.isRenaming[i]) {
            this.isRenaming[i] = true;
            setTimeout(async () => {
                const inputEl = document.querySelector("#rename");
                inputEl.focus();
            }, 300);
        }
        else {
            if (file.name.length === 0) {
                this.service.toast.error("이름은 비워둘 수 없습니다");
                return;
            }

            let filter = this.files.filter(item => item.name === file.name);

            let { code } = await wiz.call("rename", { base: this.base, name: file.name, path: file.path });
            if (code === 200) {
                this.isRenaming[i] = false;

                let filePath = file.path.split(this.root);
                file.path = this.root + filePath.slice(1).join(this.root);

                let res = this.dataSource.data.find(item => item.path === this.base);
                await this.refresh(res);

                await this.load();
            }
            else if (code === 201) {
                this.isRenaming[i] = false;
                this.service.toast.error("존재하지 않는 파일입니다.");
                return;
            }
            else if (code === 202) {
                this.service.toast.error("이미 존재하는 이름입니다.");

                setTimeout(async () => {
                    const inputEl = document.querySelector("#rename");
                    inputEl.focus();
                }, 300);

                return;
            }
            else {
                this.isRenaming[i] = false;
                alert("rename 과정에서 오류가 발생했습니다.");
                return;
            }
        }
        await this.service.render();
    }

    private async checkAll() {
        for (const file of this.files) {
            file.checked = this.selectAll;
            if (file.checked) {
                this.show = true;
            }
            else {
                this.show = false;
            }
        }
    }

    private async checked(file) {
        file.checked = !file.checked;
        this.selectAll = this.files.every(file => file.checked);
        let sum = 0;
        for (let file of this.files) {
            if (file.checked) {
                sum += 1;
            }
        }
        if (sum >= 2) {
            this.show = true;
        }
        else {
            this.show = false;
        }


        // macro
        this.macroTF = false;
        if (file.checked == true) {
            this.fileSelect.push(file);
        } else {
            let copy = this.fileSelect;
            this.fileSelect = copy.filter((element) => element !== file);
        }
        if (this.fileSelect.length == 1 && this.fileSelect[0].type == "file") {
            let { code, data } = await wiz.call("macro", { "filepath": file.path });
            if (code != 200) return;
            this.macro = data;
            this.macroTF = true;
        }
        await this.service.render();
    }


    public filesize(value) {
        if (!value) return "--";
        let kb = value / 1024;
        if (kb < 1) return value + "B";
        let mb = kb / 1024;
        if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
        let gb = mb / 1024;
        if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
        return Math.round(gb * 100) / 100 + "GB";
    }
}

export default PageStrorageComponent;