import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.list.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-list-user',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/component.list.user/view.scss */
/* src/app/component.list.user/view.scss: no such file or directory */`],
})
export class ComponentListUserComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/");
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';

    public search: any = {
        page: 1,
        text: ''
    };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        this.listloaded = false;
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text, status: status });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.listloaded = true;
        this.status = status;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async changeRole(item: any) {
        let res = false;
        if (item.role == 'admin') {
            res = await this.alert('관리자 권한을 해제하시겠습니까??', 'error');
        } else {
            res = await this.alert('관리자로 지정하시겠습니까?', 'success');
        }
        if (!res) return;
        item.membership = item.membership == 'admin' ? 'user' : 'admin';
        await wiz.call("changeRole", item);
        await this.load(this.search.page);
    }

    public registerModal: any;
    public registerEvent: any = {
        close: async () => {
            this.registerModal = null;
            await this.service.render();
        }
    }

    public async register() {
        this.registerModal = true;
        await this.service.render();
    }

    public infoModal: any;
    public infoEvent: any = {
        close: async () => {
            this.infoModal = null;
            await this.service.render();
        }
    }

    public async info(user) {
        this.infoModal = user.id;
        await this.service.render();
    }
}

export default ComponentListUserComponent;