import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/page.mypage/view.scss */
.card {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1019607843);
  border-radius: 12px;
  margin: 15px 10px;
  height: 390px;
}

.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/background/private.jpg");
}

.content {
  padding-top: 24px;
}
.content.view-max {
  min-height: 100%;
}
.content.content-light {
  background-color: #E9E8EE;
}

.sticky-menu {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: sticky !important;
  z-index: 999999 !important;
}
.sticky-menu .btn {
  height: 45px;
}
.sticky-menu .search-input {
  margin: 0 4px;
}
.sticky-menu .search-input .form-control {
  width: 220px;
  height: 45px;
}

.round-0 {
  border-radius: 0 !important;
}

.content-container {
  position: relative;
  z-index: 0;
}

.card {
  border-radius: 4px;
}
.card.card-info {
  background: #F0F1F3;
  border: none;
  box-shadow: none;
}
.card.card-info .mb-2 {
  display: flex;
}
.card.card-info .card-title {
  font-weight: 1000;
}
.card.card-info i {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  flex: none;
}
.card.card-info .info-title {
  font-weight: 600;
  width: 96px;
  flex: none;
}
.card.card-info .info-content {
  flex: auto;
  text-align: right;
}

.alert h4 {
  font-size: 18px;
  margin-bottom: 12px;
}
.alert ol {
  font-size: 16px;
  line-height: 1.3;
}

h2.title.cursor-pointer:hover {
  color: var(--wc-red);
}

.info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.info-context .form-control,
.info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.info-context:last-child {
  margin-bottom: 0;
}
.info-context .td {
  flex: 1;
}
.info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}

.main-container {
  padding: 24px 32px;
  width: 100%;
  max-width: 1160px;
  background-color: #F0F1F3;
  border-radius: 24px;
  margin-bottom: 42px;
}
.main-container .title {
  font-family: SUIT;
  font-size: 24px;
  font-weight: 1000;
  margin-bottom: 16px;
}
.main-container .form-selectgroup-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.main-container .form-control,
.main-container .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
  flex: 1;
}
.main-container .tag {
  display: inline-block;
  padding: 0 12px;
}
.main-container .tag > * {
  display: inline-block;
}

.featured-image .featured-text {
  display: none;
}
.featured-image:hover .featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.featured-image:hover .featured-text i:hover {
  color: var(--wc-red);
}

.info-context img {
  max-width: 320px;
}`],
})
export class PageMypageComponent implements OnInit {
    public data: any;
    public passwd: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");
        await this.load();
        if (["developer", "admin"].includes(this.service.auth.session.role)) {
            // await this.loadStorage();
            // await this.loadGitlab();
        }
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        this.data = data;
        await this.service.render();
    }

    public storage = {
        exists: null,
    };

    public gitlab = {
        exists: null,
    };

    public async connect() {
        const { code } = await wiz.call("connect");
        if (code !== 200) return alert("실패. 시스템 관리자에게 문의하세요")
        window.open("http://172.17.2.6:" + this.service.auth.session.port)
    }

    public async rebuild() {
        const { code } = await wiz.call("rebuild");
        if (code !== 200) return alert("실패. 시스템 관리자에게 문의하세요")
        window.open("http://172.17.2.6:" + this.service.auth.session.port)
    }

    public timestamp() {
        return new Date().getTime();
    }

    public async update() {
        return await this.service.error("개발 중인 기능입니다.");
        await this.service.loading.show();
        const { code, data } = await wiz.call("update", { data: JSON.stringify(this.data) });
        this.passwd = {};

        await this.load();
        await this.service.loading.hide();
        await this.service.alert.show({ title: "마이페이지", message: "회원 정보가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
    }

    public async updatePassword() {
        return await this.service.error("개발 중인 기능입니다.");
        if (this.passwd.change != this.passwd.repeat) {
            await this.alert("비밀번호가 일치하지 않습니다");
            return
        }

        if (this.passwd.change.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (this.passwd.change.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (this.passwd.change.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");

        await this.service.loading.show();
        let passwd: any = {
            current: this.service.auth.hash(this.passwd.current),
            change: this.service.auth.hash(this.passwd.change)
        }
        const { code } = await wiz.call("update_password", passwd);
        await this.service.loading.hide();
        if (code == 200) {
            await this.service.alert.show({ title: "마이페이지", message: "비밀번호가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
            this.passwd = {};
        } else {
            await this.alert("비밀번호를 확인해주세요");
        }
        await this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "마이페이지", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }
}

export default PageMypageComponent;