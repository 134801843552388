import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.video');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-editor-video',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/portal.dizest.editor.video/view.scss */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--wc-light);
}

video {
  width: 100%;
}`],
})
export class PortalDizestEditorVideoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    @Input() tab: any = {};
    public loading: boolean = true;

    public async ngOnInit() {
        await this.service.init();

        this.tab.data = "";

        await this.service.render();
    }

    public async wizOnTabInit() {
        this.loading = true;
        await this.service.render();

        this.loading = false;
        await this.service.render();
    }

    public async wizOnTabHide() {
        this.loading = true;
        await this.service.render();
    }

    public imageURI() {
        return this.tab.dizest.api.url('drive', 'video/' + this.tab.id);
    }

    public async download() {
        let url = this.tab.dizest.api.url('drive', 'download/' + this.tab.id);
        window.open(url, '_blank');
    }
}

export default PortalDizestEditorVideoComponent;