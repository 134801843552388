import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.sidebar.packages');
import { OnInit, OnDestroy } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-dizest-sidebar-packages',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/portal.dizest.sidebar.packages/view.scss */
.workspace-tab {
  height: 100%;
  width: 50vw;
  max-width: 520px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  background: #fff;
  background: var(--wc-background);
  border-left: 1px solid var(--wc-border);
  position: relative;
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wc-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.featured-image {
  max-width: 100%;
  max-height: 280px;
}

.nowrap {
  white-space: nowrap;
}

.card-table {
  border-bottom: none;
}
.card-table td,
.card-table th {
  padding: 8px 12px;
}

.pip-list {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.pip-list .card-table {
  height: 100%;
  overflow: auto;
}`],
})
export class PortalDizestSidebarPackagesComponent implements OnInit, OnDestroy {
    @Input() sidebar: any = null;

    public loading: boolean = true;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.refresh();
    }

    public async ngOnDestroy() {
    }

    public list: any = [];
    public query: string = '';

    public async request(fnname: string, query: any = {}) {
        let dizest = this.sidebar.selected.dizest;
        query.workflow_id = this.sidebar.selected.workflow.id;
        const { code, data } = await dizest.api.call('conda', fnname, query);
        return { code, data };
    }

    public async refresh() {
        let workflow = this.sidebar.selected.workflow;
        let spec = (await workflow.spec()).name;
        const { data } = await this.request("pip/list", { spec: spec });
        this.list = data;
        this.loading = false;
        await this.service.render();
    }

    public async remove(comment) {
        this.loading = true;
        await this.service.render();
        await this.request("remove", { comment: comment });
        await this.refresh();
    }

    public async add() {
        if (!this.query) return;
        this.loading = true;
        await this.service.render();
        let workflow = this.sidebar.selected.workflow;
        let spec = (await workflow.spec()).name;
        await this.request("pip/install", { spec: spec, package: this.query });
        await this.refresh();
    }

    public async close() {
        await this.sidebar.close();
    }
}

export default PortalDizestSidebarPackagesComponent;