import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.workflow');
import { OnInit } from "@angular/core";
import { ElementRef, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Dizest } from "src/libs/portal/dizest/dizest";

// Drive Editor
import WorkflowEditor from "src/app/portal.dizest.editor.workflow/portal.dizest.editor.workflow.component";
import TextEditor from "src/app/portal.dizest.editor.text/portal.dizest.editor.text.component";
import ImageEditor from "src/app/portal.dizest.editor.image/portal.dizest.editor.image.component";
import VideoEditor from "src/app/portal.dizest.editor.video/portal.dizest.editor.video.component";

// Setting Editor
import UIModeEditor from "src/app/portal.dizest.editor.uimode/portal.dizest.editor.uimode.component";
import SettingEditor from "src/app/portal.dizest.editor.setting/portal.dizest.editor.setting.component";
import TerminalEditor from "src/app/portal.dizest.editor.terminal/portal.dizest.editor.terminal.component";

import CodeFlowSidebar from "src/app/portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component";
import WorkflowInfoSidebar from "src/app/portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component";
import TimerSidebar from "src/app/portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component";
import PackagesSidebar from "src/app/portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component";

import DrawflowNodeComponent from "src/app/portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component";

@Component({
    selector: 'wiz-page-workflow',
template: templateSource || '',
    styles: [`

/* file: /var/www/lowcode/project/main/build/src/app/page.workflow/view.scss */
.workflow-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-layout {
    display: block;
  }
  .workflow-layout.tab-selected .workflow-nav {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-sidebar-view {
    display: none;
  }
  .workflow-layout.tab-selected .workflow-content {
    height: 100%;
  }
  .workflow-layout .apps-click {
    display: none !important;
  }
}

.workflow-nav {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .workflow-nav {
    width: 100%;
  }
}
.workflow-nav .workflow-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 49%;
  right: 0;
  width: 16px;
  height: 64px;
  background: var(--wc-border);
  font-size: 16px;
  cursor: pointer;
  z-index: 1900;
}
.workflow-nav .workflow-toggle:hover {
  background: var(--wc-blue);
  color: #fff;
}
.workflow-nav .workflow-toggle.toggle-hide {
  position: fixed;
  left: 0;
}
.workflow-nav .view-content {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-nav .view-content {
    width: 100%;
  }
}
.workflow-nav .view-content .view-header {
  padding: 0;
  padding-top: 0;
  background-color: var(--wc-blue);
  color: #fff;
}
.workflow-nav .view-content .view-header .view-title {
  display: flex;
  padding: 5px 16px;
  justify-content: center;
}
.workflow-nav .view-content .view-header .view-title img {
  width: 190px;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.workflow-nav .view-content .view-header .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.workflow-nav .view-content .view-header .clicks .click {
  font-family: "main-eb";
  cursor: pointer;
  padding: 8px;
  display: inline-block;
  flex: 1;
  position: relative;
  background-color: var(--wc-blue);
  color: #fff;
  font-size: 20px;
}
.workflow-nav .view-content .view-header .clicks .click .action-btn {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
}
.workflow-nav .view-content .view-header .clicks .click:hover {
  background-color: var(--wc-blue-hover);
}
.workflow-nav .view-content .view-header .clicks .click.active .action-btn {
  background-color: #fff;
  color: var(--wc-blue);
}
.workflow-nav .view-content .view-header .clicks .click.active .action-border {
  left: -40%;
  top: 100%;
  width: 180%;
  height: 8px;
  position: absolute;
  background-color: var(--wc-blue);
  clip-path: url(#menu);
  transform: scale(1, -1);
  z-index: 1080;
}
.workflow-nav .view-content .view-header .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.workflow-nav .view-content .view-body {
  height: 100%;
  flex: auto;
  border-right: 1px solid var(--wc-border);
  overflow: hidden;
}
@media (max-width: 768px) {
  .workflow-nav .view-content .view-body {
    border-right: none;
  }
}
@media (max-width: 768px) {
  .workflow-nav .workflow-toggle {
    display: none;
  }
}

.workflow-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
}
.workflow-content .view-content {
  flex: 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .workflow-plugin-viewer {
    display: none;
  }
}

.workflow-sidebar-view {
  height: 100%;
}

.workflow-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 42px;
  border-left: 1px solid var(--wc-border);
  background-color: #fff;
}
@media (max-width: 768px) {
  .workflow-sidebar {
    display: none;
  }
}
.workflow-sidebar .btn {
  display: block;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.workflow-sidebar .btn,
.workflow-sidebar .btn .hover-area {
  padding: 8px 12px;
}
.workflow-sidebar .btn .hover-area {
  display: none;
  height: 36px;
  right: 0;
  margin-top: -28px;
  min-width: 80px;
  background-color: var(--wc-blue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.workflow-sidebar .btn .hover-area .sidemenu-icon {
  width: 42px;
  text-align: center;
}
.workflow-sidebar .btn:hover .hover-area {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
  right: 0;
}
.workflow-sidebar .btn:hover .hover-area span {
  margin-left: auto;
  margin-right: 4px;
}

.drive-view {
  height: 100%;
  width: 100%;
  overflow: hidden;
}`],
})
export class PageWorkflowComponent implements OnInit {
    public dizest: Dizest;

    @ViewChild('workflowsidebar')
    public sidebarElement: ElementRef;

    public shortcuts: any = [];

    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public viewContainerRef: ViewContainerRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");

        // let zone = this.service.auth.session.zone;
        this.dizest = new Dizest(this, "krdc");
        await this.bindShortcuts();
        await this.dizest.loadActive();
        try {
            let res = await this.service.request.post("/setting");
            if (res.data.title)
                document.title = res.data.title;
        } catch (e) {
        }
    }

    public workflow_id: any;

    public view: any = {
        nav: 'drive',
        hideNav: false,
        plugin: null
    };

    public async switchNav(nav: string) {
        this.view.nav = nav;
        await this.service.render();
    }

    public async toggleNav() {
        this.view.hideNav = !this.view.hideNav;
        await this.service.render();
    }

    // define view for filetype
    public findExtMapper(extension) {
        extension = extension.toLowerCase();
        for (let key in this.extMapper)
            if (this.extMapper[key].match && this.extMapper[key].match(extension))
                return this.extMapper[key];
        return this.extMapper._default;
    }

    public extMapper: any = {
        // dizest workflow
        dwp: {
            match: (ext: string) => ext == 'dwp',
            sidebar: [
                { icon: 'fa-solid fa-book', title: 'Workflow Info', id: 'info', view: WorkflowInfoSidebar },
                { icon: 'fa-solid fa-code', title: 'Codeflow', id: 'codeflow', view: CodeFlowSidebar },
                { icon: 'fa-solid fa-stopwatch', title: 'Timer', id: 'timer', view: TimerSidebar },
                { icon: 'fa-brands fa-python', title: 'Packages', id: 'packages', view: PackagesSidebar },
            ],
            editor: async (tab: any) => {
                tab.rootTab = this.tab;
                tab.view = WorkflowEditor;
                tab.alert = this.statusbar.alert;
                tab.dizest = this.dizest;
                tab.socket = () => wiz.socket();
                tab.render = async () => await this.service.render();
                tab.sidebar = this.sidebar;
                tab.onCreatedRef = async (ref: any) => {
                    ref.instance.DrawflowNodeComponent = DrawflowNodeComponent;
                }
                let finded: any = this.tab.find(tab.id);
                tab = await this.tab.open(tab);
                if (!finded)
                    await this.switchNav('apps');
            }
        },
        video: {
            match: (ext: string) => ['mp4'].includes(ext),
            sidebar: [],
            editor: async (tab: any) => {
                tab.view = VideoEditor;
                tab.alert = this.statusbar.alert;
                tab.dizest = this.dizest;
                tab.render = async () => await this.service.render();
                tab.sidebar = this.sidebar;
                tab = await this.tab.open(tab);
            }
        },
        // image viewer
        image: {
            match: (ext: string) => ['png', 'jpg', 'jpeg', 'gif', 'ico', 'icon'].includes(ext),
            sidebar: [],
            editor: async (tab: any) => {
                tab.view = ImageEditor;
                tab.alert = this.statusbar.alert;
                tab.dizest = this.dizest;
                tab.render = async () => await this.service.render();
                tab.sidebar = this.sidebar;
                tab = await this.tab.open(tab);
            }
        },
        // default: text editor
        _default: {
            sidebar: [],
            editor: async (tab: any) => {
                tab.view = TextEditor;
                tab.alert = this.statusbar.alert;
                tab.dizest = this.dizest;
                tab.render = async () => await this.service.render();
                tab.sidebar = this.sidebar;
                tab = await this.tab.open(tab);
            }
        }
    }

    // UI Component Options
    public sidebar: any = {
        active: null,
        ref: null,
        close: async () => {
            this.sidebar.active = null;
            this.sidebarElement.nativeElement.innerHTML = "";
            if (this.sidebar.ref) this.sidebar.ref.destroy();
            await this.service.render();
        },
        toggle: async (item_id: string, forced: any = null) => {
            if (['setting', 'terminal'].includes(item_id)) {
                let tab = {
                    id: `.dizest/${item_id}`,
                    title: item_id,
                    extension: ''
                };

                if (item_id == 'setting') tab.view = SettingEditor;
                else if (item_id == 'terminal') {
                    tab.view = TerminalEditor;
                    tab.id = `.dizest/${item_id}/` + new Date().getTime();
                }

                tab.alert = this.statusbar.alert;
                tab.dizest = this.dizest;
                tab.render = async () => await this.service.render();
                tab.sidebar = this.sidebar;
                await this.tab.open(tab);
                await this.sidebar.toggle("codeflow", false);
                return;
            }

            let closeSidebar = async () => {
                this.sidebar.active = null;
                this.sidebarElement.nativeElement.innerHTML = "";
                if (this.sidebar.ref) this.sidebar.ref.destroy();
                await this.service.render();
            };

            if (forced === false) {
                await closeSidebar();
                return;
            }

            let item: any = null;
            let ext: string = this.tab.selected.extension;
            let data: any = this.findExtMapper(ext);
            for (let i = 0; i < data.sidebar.length; i++)
                if (data.sidebar[i].id == item_id) {
                    item = data.sidebar[i];
                    break;
                }

            if (!item) return;

            if (this.sidebar.active == item.id) {
                if (!forced) {
                    await closeSidebar();
                    return;
                }
            } else {
                await closeSidebar();
            }

            const ref = this.viewContainerRef.createComponent<NodeComponent>(item.view);

            let sidebar: any = {};
            sidebar.close = async () => {
                await closeSidebar();
            }

            sidebar.selected = this.tab.selected;

            ref.instance.sidebar = sidebar;
            let sidebarElement = ref.location.nativeElement;
            this.sidebarElement.nativeElement.innerHTML = "";
            this.sidebarElement.nativeElement.append(sidebarElement);

            this.sidebar.active = item.id;
            this.sidebar.ref = ref;
            await this.service.render();
        },
        render: () => {
            try {
                let ext = this.tab.selected.extension;
                let data = this.findExtMapper(ext);
                if (data && data.sidebar) return data.sidebar;
            } catch (e) {
            }
            return [];
        }
    };

    public tab: any = {
        onLoad: async () => { },
        on: async (action: string) => {
            if (action == 'open') {
                if (!this.tab.selected.workflow)
                    await this.switchNav('drive');
                if (this.tab.selected.id) {
                    await this.statusbar.setTitle(this.tab.selected.id, "fa-solid fa-hdd");
                } else {
                    await this.statusbar.setTitle("/", "fa-solid fa-hdd");
                    if (this.sidebar.active)
                        await this.sidebar.toggle(this.sidebar.active, false);
                }

                if (this.tab.selected.extension != 'dwp') {
                    if (this.sidebar.active)
                        await this.sidebar.toggle(this.sidebar.active, false);
                }
            } else if (action == 'close') {
                let tabs: any = this.tab.list();
                let exists: boolean = false;
                for (let i = 0; i < tabs.length; i++) {
                    if (tabs[i].workflow) {
                        exists = true;
                        break;
                    }
                }
                if (!exists)
                    await this.switchNav('drive');
                await this.statusbar.setTitle("/", "fa-solid fa-hdd");
                if (this.sidebar.active)
                    await this.sidebar.toggle(this.sidebar.active, false);
            }

        }
    };

    public drive: any = {
        tab: this.tab,
        open: async (node: any, extension: string) => {
            let tab = {
                id: node.id,
                title: node.title,
                extension: extension
            };

            let extMapper: any = this.findExtMapper(extension);
            if (extMapper) tab = await extMapper.editor(tab);
            if (tab) await this.tab.open(tab);
            return true;
        }
    };

    public apps: any = {
        tab: this.tab
    };

    public statusbar: any = {
        onLoad: async () => {
            await this.statusbar.setTitle("/", "fa-solid fa-hdd");
            await this.statusbar.alert.info('dizest started', 2000);
            await this.service.render();
        }
    };

    public async bindShortcuts() {
        this.shortcuts.push({
            key: ["cmd + s", "ctrl + s"],
            preventDefault: true,
            command: async () => {
                if (!this.tab.selected) return;

                if (this.tab.selected.view == TextEditor) {
                    await this.statusbar.alert.info('update file', 2000);
                    let path = this.tab.selected.id;
                    let data = this.tab.selected.data;
                    await this.dizest.api.call(`drive`, `update_file`, { id: path, data: data });
                } else if (this.tab.selected.view == WorkflowEditor || this.tab.selected.view == UIModeEditor) {
                    let workflow = this.tab.selected.workflow;
                    let res = await workflow.update();
                    if (!res) {
                        await this.statusbar.alert.error('An error occurred while saving', 3000);
                        return;
                    }
                    await this.statusbar.alert.info('update workflow', 1000);
                }
            }
        }, {
            key: ["shift + enter"],
            preventDefault: true,
            command: async () => {
                if (!this.tab.selected || !this.tab.selected.workflow) return;
                let workflow = this.tab.selected.workflow;
                if (!workflow.flow.selected) return;
                let flow = workflow.flow.get(workflow.flow.selected);
                flow.status('pending');
                await flow.logclear();
                await flow.run();
            }
        }, {
            key: ["alt + w"],
            preventDefault: true,
            command: async () => {
                if (!this.tab.selected) return;
                if (this.tab.selected.workflow) {
                    let workflow = this.tab.selected.workflow;
                    if (workflow.flow.selected) {
                        let flow = workflow.flow.get(workflow.flow.selected);
                        workflow.codeflow.close(flow);
                        await workflow.flow.select();
                        return;
                    }
                }

                if (this.sidebar.active) {
                    await this.sidebar.toggle(this.sidebar.active, false);
                    return;
                }

                if (!this.sidebar.active && this.tab.selected.close)
                    await this.tab.selected.close();
            }
        }, {
            key: ["cmd + r", "ctrl + r"],
            preventDefault: true,
            command: async () => {
                if (!this.tab.selected || !this.tab.selected.workflow) return;
                let workflow = this.tab.selected.workflow;
                if (!workflow.flow.selected) return;
                let flow = workflow.flow.get(workflow.flow.selected);
                flow.status('pending');
                await flow.logclear();
                await flow.run();
            }
        }, {
            key: ["alt + 1"],
            preventDefault: true,
            command: async () => {
                await this.switchNav("drive");
            }
        }, {
            key: ["alt + 2"],
            preventDefault: true,
            command: async () => {
                await this.switchNav("apps");
            }
        }, {
            key: ["alt + 3"],
            preventDefault: true,
            command: async () => {
                await this.switchNav("health");
            }
        }, {
            key: ["cmd + 1", "ctrl + 1"],
            preventDefault: true,
            command: async () => {
                let tab = this.tab.findByIndex(0);
                if (tab) await tab.open();
            }
        }, {
            key: ["cmd + 2", "ctrl + 2"],
            preventDefault: true,
            command: async () => {
                let tab = this.tab.findByIndex(1);
                if (tab) await tab.open();
            }
        }, {
            key: ["cmd + 3", "ctrl + 3"],
            preventDefault: true,
            command: async () => {
                let tab = this.tab.findByIndex(2);
                if (tab) await tab.open();
            }
        }, {
            key: ["cmd + 4", "ctrl + 4"],
            preventDefault: true,
            command: async () => {
                let tab = this.tab.findByIndex(3);
                if (tab) await tab.open();
            }
        }, {
            key: ["cmd + 5", "ctrl + 5"],
            preventDefault: true,
            command: async () => {
                let tab = this.tab.findByIndex(4);
                if (tab) await tab.open();
            }
        }, {
            key: ["esc"],
            preventDefault: true,
            command: async () => {
                if (!this.tab.selected || !this.tab.selected.workflow) return;
                let workflow = this.tab.selected.workflow;
                await workflow.flow.select();
            }
        });

        for (let i = 0; i < this.shortcuts.length; i++)
            this.shortcuts[i].allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];

        await this.service.render();
    }

}

export default PageWorkflowComponent;